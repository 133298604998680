<template>
    <div class="ratingsreviews_page">
        <div class="ratingsreviews">
            <div class="ratingsreviews_main">
                <!-- <div class="ratingsreviews_title">Ratings and Reviews</div> -->
                <div class="ratingsreviews_rate">
                    <p><span style="font-size:18px;font-weight: 600;">{{businessInfo.comprehensiveScore}}</span>/5 Very
                        Statisfied</p>
                    <p class="ratingsreviews_rate_p">Supplier service
                        <el-progress :percentage="businessInfo.supplierServiceScore" color="#FD6A1D"
                            :format="format"></el-progress>
                    </p>
                    <p class="ratingsreviews_rate_p">On-time shipment
                        <el-progress :percentage="businessInfo.timeShipmentScore" color="#FD6A1D"
                            :format="format"></el-progress>
                    </p>
                    <p class="ratingsreviews_rate_p">Product quality
                        <el-progress :percentage="businessInfo.productQuality" color="#FD6A1D"
                            :format="format"></el-progress>
                    </p>
                </div>
                <div class="ratingsreviews_list" v-if="dataList.length > 0">
                    <div class="ratingsreviews_list_child" v-for="(item,index) in dataList" :key="index">
                        <div class="ratingsreviews_list_child_div">
                            <div class="ratingsreviews_list_child_div_title">{{item.name}}</div>
                            <div class="ratingsreviews_list_child_div_right">
                                <div style="color:#A4AAB4;">{{item.userNick}}</div>
                                <div class="ratingsreviews_list_child_div_gray">{{item.createTime}}</div>
                            </div>
                        </div>
                        <div class="ratingsreviews_list_child_details">
                            <el-rate v-model="item.comprehensiveScore" disabled text-color="#FD6A1D"
                                score-template="{value}">
                            </el-rate>
                            <p class="ratingsreviews_list_child_tips">{{item.userAppraise}}</p>
                            <div class="ratingsreviews_list_child_div">
                                <div class="ratingsreviews_list_child_div_image">
                                    <img :src="$baseUrl+item.commodityImage" />
                                </div>
                                <div class="ratingsreviews_list_child_div_right1">{{item.commodityName}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page_nation" style="width: 100%; text-align: center" v-if="dataList.length > 0">
                    <el-pagination background :total="total.pageTotal" @current-change="currentChange"
                        :current-page="total.pageNum" :page-size="total.pageSize"
                        layout="prev, pager, next, jumper"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getBusinessAppraiseList } from "@/api/news";
    import PageNation from "@/components/pagenation/PageNation.vue";
    export default {
        name: "newCommentList",
        data() {
            return {
                businessId: sessionStorage.getItem("businessId"),
                total: {
                    pageTotal: 0,
                    pageNum: 1,
                    pageSize: 5,
                    isPage: 1,
                    businessId: sessionStorage.getItem("businessId"),
                    commodityId: this.$route.query.id
                },
                businessInfo: {},
                show: false,
                dataList: [
                    // {name:'T***c',name1:'T',dateTime:'Oct 14.2023',rate:5,
                    //   tips:"This product isfantastic!it's convenient, fits in my purse every you need compact and organized.",
                    //   image:require('@/assets/img/图层37.png'),
                    //   details:'New product USB 2.0 Connector PD Fast Charging Cabie 2 in 1Type-c To Type C PD Date Cable Fot Phone'
                    // },
                    // {name:'H***c',name1:'H',dateTime:'Oct 14.2023',rate:5,
                    //   tips:"This product isfantastic!it's convenient, fits in my purse every you need compact and organized.",
                    //   image:require('@/assets/img/图层37.png'),
                    //   details:'New product USB 2.0 Connector PD Fast Charging Cabie 2 in 1Type-c To Type C PD Date Cable Fot Phone'
                    // }
                ]
            };
        },
        components: {
            PageNation
        },
        computed: {},
        watch: {
            // $route: {
            //   handler: function (val) {
            //     //这里的路由是页面上的路由
            //     if (val.path == "/ratingsreviews") {
            //       //调用你想用的方法
            //       this.selectBusinessAppraiseList();
            //     } else {
            //       this.router_show = false;
            //     }
            //   },
            //   // 深度观察监听，普通的watch方法无法监听到对象内部属性的改变，只有data中的数据才能够监听到变化，此时就需要deep属性对对象进行深度监听。
            //   deep: true,
            // },
            //监听商品id发生变化
            $route: "idPath",
        },
        methods: {
            idPath() {
                this.total.commodityId = this.$route.query.id;
                this.selectBusinessAppraiseList();
            },
            format(percentage) {
                return (percentage / 100 * 5).toFixed(1);
            },
            // 决定pc端与移动端的显示
            businessPersonalShow() {
                // 刷新时判断
                if (document.documentElement.clientWidth > 1080) {
                    document.querySelector(".ratingsreviews_phone").style.display = "none";
                    document.querySelector(".ratingsreviews_pc").style.display = "flex";
                } else {
                    document.querySelector(".ratingsreviews_phone").style.display = "block";
                    document.querySelector(".ratingsreviews_pc").style.display = "none";
                }
            },
            getshow(data) {
                if (data) {
                    this.show = true;
                }
            },
            businesshomeClick() {
                this.$router.push({
                    path: "/businesshome",
                    query: {
                        noBusiness: false,
                        businessId: sessionStorage.getItem("businessId"),
                    },
                });
            },
            // 获取文章列表
            selectBusinessAppraiseList() {
                getBusinessAppraiseList(this.total).then((res) => {
                    if (res.code == 200) {
                        res.data.comprehensiveScore = res.data.comprehensiveScore ? Number(res.data.comprehensiveScore.toFixed(1)) : 0
                        res.data.supplierServiceScore = res.data.supplierServiceScore ? Number((res.data.supplierServiceScore / 5 * 100).toFixed(1)) : 0
                        res.data.timeShipmentScore = res.data.timeShipmentScore ? Number((res.data.timeShipmentScore / 5 * 100).toFixed(1)) : 0
                        res.data.productQuality = res.data.productQuality ? Number((res.data.productQuality / 5 * 100).toFixed(1)) : 0
                        this.businessInfo = res.data
                        this.total.pageTotal = res.data.total;
                        // if (res.data.appraiseListVoList && res.data.appraiseListVoList.length > 0) {
                        //     this.dataList = res.data.appraiseListVoList.map(item => {
                        //         item.name = item.userNick ? item.userNick.substring(0, 1) : ''
                        //         item.comprehensiveScore = item.comprehensiveScore ? Number(item.comprehensiveScore.toFixed(1)) : 0
                        //         return item
                        //     })
                        // }
                        this.dataList = res.data.appraiseListVoList.map(item => {
                            item.name = item.userNick ? item.userNick.substring(0, 1) : ''
                            item.comprehensiveScore = item.comprehensiveScore ? Number(item.comprehensiveScore.toFixed(1)) : 0
                            return item
                        })
                    }
                });
            },
            currentChange(e) {
                this.total.pageNum = e;
                this.selectBusinessAppraiseList();
            },
        },
        created() {
            let bottomnav = document.querySelector(".bottomnav");
            for (let i = 0; i < bottomnav.children.length; i++) {
                bottomnav.children[i].style.color = "#aaa";
            }
            document.querySelector(".bottomnav_about").style.color = "#13AF87";
        },
        mounted() {
            this.selectBusinessAppraiseList()
            this.businessPersonalShow();
            // 屏幕变化时判断
            window.addEventListener("resize", () => {
                this.businessPersonalShow();
            });
        },
    };
</script>

<style scoped>
    .ratingsreviews_page {
        width: 100%;
        background-color: #f1f1f1;
        /* padding: 0 calc(20 * 100vw / 1920); */
    }

    .ratingsreviews_page .box_4 {
        width: 100%;
        /* height: calc(280 * 100vw / 1080); */
    }

    .ratingsreviews {
        /*width: calc(1200 * 100vw / 1920);*/
        width: 100%;
        margin: calc(20 * 100vw / 1920) auto;
        background-color: #f1f1f1;
    }

    .ratingsreviews .directory {
        background-color: #f1f1f1;
        margin: 0;
        padding-bottom: 1.2vw;
        font-size: 0.93vw;
        font-family: Roboto;
        font-weight: 400;
        color: #999999;
    }

    .ratingsreviews .ratingsreviews_main {
        background-color: #fff;
        padding-bottom: calc(20 * 100vw / 1920);
        /* min-height: calc(640 * 100vw / 1920) */
    }

    .ratingsreviews_main .ratingsreviews_title {
        margin-left: calc(20 * 100vw / 1920);
        width: calc(140 * 100vw / 1920);
        height: calc(75 * 100vw / 1920);
        white-space: nowrap;
        line-height: calc(75 * 100vw / 1920);
        border-bottom: 5px solid rgba(19, 175, 135, 1);
        font-weight: bolder;
        /* font-size: 1.28vw; */
        font-size: 15.4px;
    }

    .ratingsreviews_main .ratingsreviews_rate {
        margin-top: calc(60 * 100vw / 1920);
        margin-bottom: calc(50 * 100vw / 1920);
        width: calc(700 * 100vw / 1920);
    }

    .ratingsreviews_main .ratingsreviews_rate>p {
        /* font-size: 1.18vw; */
        font-size: 14.4px;
        margin-left: calc(40 * 100vw / 1920);
        margin-bottom: calc(40 * 100vw / 1920);
        display: flex;
        align-items: center;
    }

    .ratingsreviews_rate_p {
        justify-content: space-between;
    }

    .ratingsreviews_main .ratingsreviews_rate>p .el-progress {
        width: calc(100% - calc(400 * 100vw / 1920));
    }

    .ratingsreviews_list {
        margin-left: calc(40 * 100vw / 1920);
        min-height: calc(640 * 100vw / 1920);
    }

    .ratingsreviews_main .ratingsreviews_list_child {
        margin-bottom: calc(40 * 100vw / 1920);
    }

    .ratingsreviews_main .ratingsreviews_list_child .ratingsreviews_list_child_div {
        display: flex;
        align-items: center;
        color: #BDBDBD;
        font-size: 1.18vw;
        margin-bottom: calc(20 * 100vw / 1920);
    }

    .ratingsreviews_list_child_div_title {
        width: calc(50 * 100vw / 1920);
        height: calc(50 * 100vw / 1920);
        text-align: center;
        line-height: calc(50 * 100vw / 1920);
        background-color: #4C63D5;
        color: #fff;
        font-size: 1.48vw;
        font-weight: bolder;
        border-radius: 50%;
        margin-right: calc(20 * 100vw / 1920);
    }

    .ratingsreviews_list_child_div_gray {
        margin-top: calc(10 * 100vw / 1920);
    }

    .ratingsreviews_list_child_details {
        padding-left: calc(10 * 100vw / 1920);
        border-left: 1px solid #BDBDBD;
        width: clac(100% - calc(10 * 100vw / 1920));
    }

    .ratingsreviews_list_child_details .el-icon-star-on {
        color: #FD6A1D !important;
    }

    .ratingsreviews_list_child_div_right {
        width: calc(100% - calc(70 * 100vw / 1920));
    }

    .ratingsreviews_list_child_div_right1 {
        width: calc(100% - calc(80 * 100vw / 1920));
    }

    .ratingsreviews_list_child_rate {
        margin-bottom: calc(20 * 100vw / 1920);
    }

    .ratingsreviews_list_child_tips {
        color: #979B9F;
        font-size: 1.28vw;
        margin-bottom: calc(20 * 100vw / 1920);
    }

    .ratingsreviews_list_child_div_image {
        margin-right: calc(20 * 100vw / 1920);
    }

    .ratingsreviews_list_child_div_image>img {
        width: calc(60 * 100vw / 1920);
        height: calc(60 * 100vw / 1920);
    }

    @media screen and (min-width: 1080px) {
        /* .ratingsreviews .ratingsreviews_main {
      background-color: #fff;
      padding-bottom: calc(40 * 100vw / 1080);
      
    }
    .ratingsreviews_main .ratingsreviews_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(150 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(150 * 100vw / 1080);
      border-bottom: 5px solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: 1.28vw;
    }
    .ratingsreviews_main .ratingsreviews_rate{
      margin-top: calc(120 * 100vw / 1080);
      margin-bottom: calc(100 * 100vw / 1080);
      width: 100%;
    }
    .ratingsreviews_main .ratingsreviews_rate>p{
      font-size: 2.18vw;
      margin-left: calc(80 * 100vw / 1080);
      margin-bottom: calc(80 * 100vw / 1080);
      display: flex;  
      align-items: center;
    }
    .ratingsreviews_rate_p{  
      justify-content: space-between;
    }
    .ratingsreviews_main .ratingsreviews_rate>p .el-progress{
      width: calc(100% - calc(800 * 100vw / 1080));
    }
    .ratingsreviews_list{
      margin-left: calc(80 * 100vw / 1080);
      min-height: calc(840 * 100vw / 1080);
    }
    .ratingsreviews_main .ratingsreviews_list_child{
      margin-bottom: calc(80 * 100vw / 1080);
    }
    .ratingsreviews_main .ratingsreviews_list_child .ratingsreviews_list_child_div{
      display: flex;  
      align-items: center;
      color: #BDBDBD;
      font-size: 2.18vw;
      margin-bottom: calc(40 * 100vw / 1080);
    } 
    .ratingsreviews_list_child_div_title{
      width: calc(100 * 100vw / 1080);
      height: calc(100 * 100vw / 1080);
      text-align: center;
      line-height: calc(100 * 100vw / 1080);
      background-color: #4C63D5;
      color: #fff;
      font-size: 2.48vw;
      font-weight: bolder;
      border-radius: 50%;
      margin-right: calc(40 * 100vw / 1080);
    }
    .ratingsreviews_list_child_div_gray{
      margin-top: calc(20 * 100vw / 1080);
    }
    .ratingsreviews_list_child_details{
      padding-left: calc(20 * 100vw / 1080);
      border-left: 1px solid #BDBDBD;
      width: clac(100% - calc(20 * 100vw / 1080));
    }
    .ratingsreviews_list_child_details .el-icon-star-on{
      color: #FD6A1D !important;
    }
    .ratingsreviews_list_child_div_right{
      width: calc(100% - calc(140 * 100vw / 1080));
    }
    .ratingsreviews_list_child_div_right1{
      width: calc(100% - calc(160 * 100vw / 1080));
    }
    .ratingsreviews_list_child_rate{
      margin-bottom: calc(40 * 100vw / 1080);
    }
    .ratingsreviews_list_child_tips{
      color: #979B9F;
      font-size: 2.28vw;
      margin-bottom: calc(40 * 100vw / 1080);
    }
    .ratingsreviews_list_child_div_image{  
      margin-right: calc(40 * 100vw / 1080);
    }
    .ratingsreviews_list_child_div_image>img{
      width: calc(120 * 100vw / 1080);
      height: calc(120 * 100vw / 1080);
    } */

        .contact_info {
            display: flex;
        }

        .contact_info_img {
            flex: 1;
            height: auto;
            padding: 0 10px;
            order: 2;
        }

        .ratingsreviews_main .contact_info_message {
            padding: 0 calc(28 * 100vw / 1920);
            margin-top: calc(20 * 100vw / 1920);
            flex: 1;
        }
    }

    .ratingsreviews_main .contact_info_message .address img {
        width: calc(30 * 100vw / 1920);
        height: calc(30 * 100vw / 1920);
    }

    .ratingsreviews_main .contact_info_message .address,
    .ratingsreviews_main .contact_info_message .phone {
        border-bottom: 1px solid #ddd;
        margin-bottom: calc(25 * 100vw / 1920);
    }

    .ratingsreviews_main .contact_info_message .phone img {
        width: calc(30 * 100vw / 1920);
        height: calc(30 * 100vw / 1920);
    }

    .ratingsreviews_main .contact_info_message .email img {
        width: calc(30 * 100vw / 1920);
        height: calc(22 * 100vw / 1920);
    }

    .ratingsreviews_main .contact_info_message .text1 {
        font-size: calc(26 * 100vw / 1920);
        font-family: Roboto;
        font-weight: 500;
        color: #666666;
        margin-left: calc(16 * 100vw / 1920);
    }

    .ratingsreviews_main .contact_info_message .text2 {
        display: block;
        font-size: calc(18 * 100vw / 1920);
        font-family: Roboto;
        font-weight: 400;
        color: #333333;
        /* line-height: calc(18 * 100vw / 1920); */
        margin-top: calc(20 * 100vw / 1920);
        padding-bottom: calc(32 * 100vw / 1920);
    }

    @media screen and (max-width: 1080px) {
        .ratingsreviews_page {
            width: 100vw;
            background-color: #fff;
            border-top: 1px solid #ddd;
        }

        .ratingsreviews {
            width: 100%;
            margin: 0;
            background-color: #fff;
        }

        .ratingsreviews .directory {
            display: none;
        }

        .ratingsreviews_main .ratingsreviews_title {
            margin-left: calc(40 * 100vw / 1080);
            width: calc(280 * 100vw / 1080);
            height: calc(145 * 100vw / 1080);
            white-space: nowrap;
            line-height: calc(145 * 100vw / 1080);
            border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
            font-weight: bolder;
            /* font-size: calc(48 * 100vw / 1080); */
            font-size: 15.4px;
        }

        .ratingsreviews_main .ratingsreviews_rate {
            margin-top: calc(80 * 100vw / 1080);
            margin-bottom: calc(70 * 100vw / 1080);
            width: calc(1000 * 100vw / 1080);
        }

        .ratingsreviews_main .ratingsreviews_rate>p {
            /* font-size: 1.28vw; */
            font-size: 14.4px;
            margin-left: calc(60 * 100vw / 1080);
            margin-bottom: calc(60 * 100vw / 1080);
            display: flex;
            align-items: center;
        }

        .ratingsreviews_rate_p {
            justify-content: space-between;
        }

        .ratingsreviews_main .ratingsreviews_rate>p .el-progress {
            width: calc(100% - calc(400 * 100vw / 1080));
        }

        .ratingsreviews_list {
            margin-left: calc(40 * 100vw / 1080);
            min-height: calc(840 * 100vw / 1080);
        }

        .ratingsreviews_main .ratingsreviews_list_child {
            margin-bottom: calc(40 * 100vw / 1080);
        }

        .ratingsreviews_main .ratingsreviews_list_child .ratingsreviews_list_child_div {
            display: flex;
            align-items: center;
            color: #BDBDBD;
            font-size: 1.18vw;
            margin-bottom: calc(30 * 100vw / 1080);
        }

        .ratingsreviews_list_child_div_title {
            width: calc(100 * 100vw / 1080);
            height: calc(100 * 100vw / 1080);
            text-align: center;
            line-height: calc(100 * 100vw / 1080);
            background-color: #4C63D5;
            color: #fff;
            font-size: 1.48vw;
            font-weight: bolder;
            border-radius: 50%;
            margin-right: calc(30 * 100vw / 1080);
        }

        .ratingsreviews_list_child_div_gray {
            margin-top: calc(20 * 100vw / 1080);
        }

        .ratingsreviews_list_child_details {
            padding-left: calc(20 * 100vw / 1080);
            border-left: 1px solid #BDBDBD;
            width: calc(100% - calc(20 * 100vw / 1080));
        }

        .ratingsreviews_list_child_div_right {
            width: calc(100% - calc(130 * 100vw / 1080));
        }

        .ratingsreviews_list_child_div_right1 {
            width: calc(100% - calc(150 * 100vw / 1080));
        }

        .ratingsreviews_list_child_details .el-icon-star-on {
            color: #FD6A1D !important;
        }

        .ratingsreviews_list_child_rate {
            margin-bottom: calc(30 * 100vw / 1080);
        }

        .ratingsreviews_list_child_tips {
            color: #979B9F;
            font-size: 1.28vw;
            margin-bottom: calc(30 * 100vw / 1080);
        }

        .ratingsreviews_list_child_div_image {
            margin-right: calc(30 * 100vw / 1080);
        }

        .ratingsreviews_list_child_div_image>img {
            width: calc(120 * 100vw / 1080);
            height: calc(120 * 100vw / 1080);
        }
    }
</style>
<style>
    .ratingsreviews_text p {
        width: 100%;
        margin-bottom: calc(20 * 100vw / 1920);
        text-indent: calc(32 * 100vw / 1920);
    }

    .ratingsreviews_text p img {
        max-width: 100%;
        max-height: 100%;
    }

    @media screen and (max-width: 1080px) {
        .ratingsreviews_text p {
            width: 100%;
            margin-bottom: calc(10 * 100vw / 1080);
            font-size: calc(40 * 100vw / 1080);
        }
    }
</style>