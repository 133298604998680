<template>
  <div class="businessprofile_page">
    <div class="businessprofile">
      <p class="directory">
        <span @click="businesshomeClick" class="directory_home">Home </span>>
        Profile
      </p>
      <div class="box_4" v-show="show">
        <swiper-top @show="getshow"></swiper-top>
      </div>
      <div class="businessprofile_main">
        <div class="businessprofile_title" v-if="businessInfo.enterpriseQualification">Enterprise Qualification</div>
        <!-- <div class="box_4" v-show="show">
          <swiper-top @show="getshow"></swiper-top>
        </div> -->
        <!--<div class="businessprofile_img">
          <img
            :src="$baseUrl + businessInfo.image"
            alt=""
            v-if="businessInfo.image != null"
          />
        </div>-->
        <div class="businessprofile_img" v-if="businessInfo.enterpriseQualification">
          <img :src="$baseUrl + businessInfo.enterpriseQualification" alt="" />
          <!-- <img src="@/assets/img/图层2.png" alt="" /> -->
        </div>
        <div class="businessprofile_title" v-if="businessInfo.video || businessInfo.introduction">Company Profile</div>
        <div class="businessprofile_phone">
          <div class="businessprofile_img" v-if="businessInfo.video">
            <video :src="$baseUrl + businessInfo.video" autoplay controls loop alt="" />
            <!-- <video src="@/assets/img/风景.mp4" autoplay controls loop alt="" /> -->
          </div>
          <div class="businessprofile_text" v-html="businessInfo.introduction"></div>
        </div>
        <div class="businessprofile_pc" style="display:flex;align-items:center;border-top: 1px solid #ddd;">
          <div class="businessprofile_text" v-html="businessInfo.introduction" style="width:50%;"></div>

          <div class="businessprofile_img" style="width:50%;" v-if="businessInfo.video">
            <video :src="$baseUrl + businessInfo.video" autoplay controls loop alt="" />
            <!--<video src="@/assets/img/风景.mp4" autoplay controls loop alt="" />-->
          </div>
        </div>

        <div class="businessprofile_title">Contact info</div>
        <div class="contact_info">
          <div class="contact_info_img">
            <!-- <img :src="$baseUrl+businessInfo.mapImage" alt=""> -->
            <maps v-if="businessInfo.longitude && businessInfo.latitude" :longitude="businessInfo.longitude"
              :latitude="businessInfo.latitude"></maps>
          </div>
          <div class="contact_info_message">
            <div class="address">
              <img referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/ps6scfsp2rxl4oc0eo3eyygdp3e7u2vnxtd3ee886c-ba50-4047-bf46-557b93ae6aa0" />
              <span class="text1">Address:</span>
              <span class="text2">{{ businessInfo.address }}</span>
            </div>
            <div class="phone">
              <img referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/psyxernbqvyltnb5354a0oydrt2ebwfve372643b-8be4-4b8a-a61e-7351f7eb5e8e" />
              <span class="text1">Phone:</span>
              <span class="text2">{{ businessInfo.phone }}</span>
            </div>
            <div class="email">
              <img referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/pspfwlik28j3qtu99zxjcm88ij9des04ofdd90824-97d4-439b-ae0b-7eb5cebde1a4" />
              <span class="text1">Email:</span>
              <span class="text2">{{ businessInfo.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SwiperTop from "../businesshome/swipertop/SwiperTop.vue";
  import { businessIntroduction } from "@/api/business";
  import maps from "@/components/maps/map";
  export default {
    name: "BusinessProfile",
    data() {
      return {
        businessId: sessionStorage.getItem("businessId"),
        businessInfo: {},
        show: false,
      };
    },
    components: {
      SwiperTop,
      maps,
    },
    computed: {},
    watch: {
      $route: {
        handler: function (val) {
          //这里的路由是页面上的路由
          if (this.accountId != null && val.path == "/businessprofile") {
            //调用你想用的方法
            this.businessIntroductionApi();
          } else {
            this.router_show = false;
          }
        },
        // 深度观察监听，普通的watch方法无法监听到对象内部属性的改变，只有data中的数据才能够监听到变化，此时就需要deep属性对对象进行深度监听。
        deep: true,
      },
    },
    methods: {
      // 决定pc端与移动端的显示
      businessPersonalShow() {
        // 刷新时判断
        if (document.documentElement.clientWidth > 1080) {
          document.querySelector(".businessprofile_phone").style.display = "none";
          document.querySelector(".businessprofile_pc").style.display = "flex";
        } else {
          document.querySelector(".businessprofile_phone").style.display = "block";
          document.querySelector(".businessprofile_pc").style.display = "none";
        }
      },
      getshow(data) {
        if (data) {
          this.show = true;
        }
      },
      businesshomeClick() {
        this.$router.push({
          path: "/businesshome",
          query: {
            noBusiness: false,
            businessId: sessionStorage.getItem("businessId"),
          },
        });
      },
      //获取商家简介信息
      businessIntroductionApi() {
        businessIntroduction(this.businessId).then((res) => {
          if (res.code == 200) {
            this.businessInfo = res.data;
          } else if (res.code == 401) {
            this.$message({
              message: res.code,
              type: "error",
            });
            //未登录
            this.$router.push({
              path: "/login",
              query: {
                box8: false,
                phoneHomeImg: false,
                bool: false,
              },
            });
            return;
          } else {
            // this.$message({ message: res.msg, type: "error" });
          }
        });
      },
    },
    created() {
      this.businessIntroductionApi();
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
      document.querySelector(".bottomnav_about").style.color = "#13AF87";
    },
    mounted() {
      this.businessPersonalShow();
      // 屏幕变化时判断
      window.addEventListener("resize", () => {
        this.businessPersonalShow();
      });
    },
  };
</script>

<style scoped>
  .businessprofile_page {
    width: 100vw;
    background-color: #f1f1f1;
  }

  .businessprofile_page .box_4 {
    width: 100%;
    /* height: calc(280 * 100vw / 1080); */
  }

  .businessprofile {
    /*width: calc(1200 * 100vw / 1920);*/
    width: 100%;
    margin: calc(20 * 100vw / 1920) auto;
    background-color: #f1f1f1;
  }

  .businessprofile .directory {
    background-color: #f1f1f1;
    margin: 0;
    padding-bottom: 1.2vw;
    padding-left: calc(20 * 100vw / 1920);
    font-size: 0.93vw;
    font-family: Roboto;
    font-weight: 400;
    color: #999999;
  }

  .businessprofile .businessprofile_main {
    background-color: #fff;
    padding-bottom: calc(20 * 100vw / 1920);
  }

  .businessprofile_main .businessprofile_title {
    margin-left: calc(20 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .businessprofile_phone {
    padding: calc(20 * 100vw / 1920);
  }

  .businessprofile_pc {
    padding: calc(20 * 100vw / 1920);
  }

  .businessprofile_main .businessprofile_img {
    /* height: calc(218 * 100vw / 1920); */
    border-top: 1px solid #ddd;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: calc(890 * 100vw / 1920) calc(150 * 100vw / 1920);
    /* background-image: url(../../assets/img/图层24拷贝.png); */
    padding: calc(30 * 100vw / 1920) 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .businessprofile_img img {
    max-width: 100%;
    /* height: 100%; */
  }

  .businessprofile_img video {
    max-width: 99%;
  }

  .businessprofile_main .businessprofile_text {
    font-size: calc(30 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
    line-height: calc(32 * 100vw / 1920);
    margin-bottom: calc(24 * 100vw / 1920);
  }

  @media screen and (min-width: 1080px) {
    .contact_info {
      display: flex;
    }

    .contact_info_img {
      flex: 1;
      height: auto;
      padding: 0 10px;
      order: 2;
    }

    .businessprofile_main .contact_info_message {
      padding: 0 calc(28 * 100vw / 1920);
      margin-top: calc(20 * 100vw / 1920);
      flex: 1;
    }
  }

  .businessprofile_main .contact_info_message .address img {
    width: calc(30 * 100vw / 1920);
    height: calc(30 * 100vw / 1920);
  }

  .businessprofile_main .contact_info_message .address,
  .businessprofile_main .contact_info_message .phone {
    border-bottom: 1px solid #ddd;
    margin-bottom: calc(25 * 100vw / 1920);
  }

  .businessprofile_main .contact_info_message .phone img {
    width: calc(30 * 100vw / 1920);
    height: calc(30 * 100vw / 1920);
  }

  .businessprofile_main .contact_info_message .email img {
    width: calc(30 * 100vw / 1920);
    height: calc(22 * 100vw / 1920);
  }

  .businessprofile_main .contact_info_message .text1 {
    font-size: calc(26 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #666666;
    margin-left: calc(16 * 100vw / 1920);
  }

  .businessprofile_main .contact_info_message .text2 {
    display: block;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    /* line-height: calc(18 * 100vw / 1920); */
    margin-top: calc(20 * 100vw / 1920);
    padding-bottom: calc(32 * 100vw / 1920);
  }

  @media screen and (max-width: 1080px) {
    .businessprofile_page {
      width: 100vw;
      background-color: #fff;
      border-top: 1px solid #ddd;
    }

    .businessprofile {
      width: 100%;
      margin: 0;
      background-color: #fff;
    }

    .businessprofile .directory {
      display: none;
    }

    .businessprofile_main .businessprofile_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(145 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(145 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .businessprofile_main .businessprofile_img {
      /* height: calc(230 * 100vw / 1080); */
      padding: calc(40 * 100vw / 1080) 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: calc(890 * 100vw / 1080) calc(150 * 100vw / 1080);
      /* background-image: url(../../assets/img/图层24拷贝.png); */
    }

    .businessprofile_main .businessprofile_text {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #666666;
      line-height: calc(48 * 100vw / 1080);
      margin-bottom: calc(42 * 100vw / 1080);
    }

    .businessprofile_main .businessprofile_text p {
      margin: 0 calc(40 * 100vw / 1080);
      padding-bottom: calc(90 * 100vw / 1080);
    }

    .businessprofile_main .businessprofile_text p:nth-child(n + 4) {
      display: none;
    }

    .businessprofile_main .contact_info_img {
      height: calc(640 * 100vw / 1080);
      border-top: 1px solid #ddd;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: calc(1000 * 100vw / 1080) calc(520 * 100vw / 1080);
      /* background-image: url(../../assets/img/地图.png); */
    }

    .businessprofile_main .contact_info_message {
      padding: 0 calc(40 * 100vw / 1080);
      margin-bottom: calc(100 * 100vw / 1080);
    }

    .businessprofile_main .contact_info_message .address img {
      width: calc(40 * 100vw / 1080);
      height: calc(40 * 100vw / 1080);
    }

    .businessprofile_main .contact_info_message .address,
    .businessprofile_main .contact_info_message .phone {
      border-bottom: none;
      margin-bottom: calc(85 * 100vw / 1080);
    }

    .businessprofile_main .contact_info_message .phone img {
      width: calc(40 * 100vw / 1080);
      height: calc(40 * 100vw / 1080);
    }

    .businessprofile_main .contact_info_message .email img {
      width: calc(40 * 100vw / 1080);
      height: calc(30 * 100vw / 1080);
    }

    .businessprofile_main .contact_info_message .text1 {
      font-size: calc(36 * 100vw / 1080);
      margin-left: calc(24 * 100vw / 1080);
    }

    .businessprofile_main .contact_info_message .text2 {
      font-size: calc(36 * 100vw / 1080);
      /* line-height: calc(18 * 100vw / 1080); */
      margin-top: calc(30 * 100vw / 1080);
      padding-bottom: 0;
    }
  }
</style>
<style>
  .businessprofile_text p {
    width: 100%;
    margin-bottom: calc(20 * 100vw / 1920);
    text-indent: calc(32 * 100vw / 1920);
  }

  .businessprofile_text p img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 1080px) {
    .businessprofile_text p {
      width: 100%;
      margin-bottom: calc(10 * 100vw / 1080);
      font-size: calc(40 * 100vw / 1080);
    }
  }
</style>